// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bS_kz";
export var caseStudyBackground__lineColor = "bS_kq";
export var caseStudyHead = "bS_kC";
export var caseStudyHead__imageWrapper = "bS_kn";
export var caseStudyProjectsSection = "bS_kB";
export var caseStudyQuote__bgRing = "bS_kw";
export var caseStudyVideo__ring = "bS_kF";
export var caseStudy__bgDark = "bS_km";
export var caseStudy__bgDarkReverse = "bS_mG";
export var caseStudy__bgLight = "bS_kl";